
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import AdministratorNavigationVue from "@/components/administrator/navigation.vue";
import AdministratorResourcesNavigationVue from "@/components/administrator/resources/navigation.vue";
import AppAdministratorResourcesRootExercisesIndexVue from "@/views/app/administrator/resources/root/exercises/index.vue";
import AppAdministratorResourcesRootDietsIndexVue from "@/views/app/administrator/resources/root/diets/index.vue";
import AppAdministratorResourcesRootSupplementsIndexVue from "@/views/app/administrator/resources/root/supplements/index.vue";

@Options({
  components: {
    ElRow,
    ElCol,
    AdministratorNavigationVue,
    AdministratorResourcesNavigationVue,
    AppAdministratorResourcesRootExercisesIndexVue,
    AppAdministratorResourcesRootDietsIndexVue,
    AppAdministratorResourcesRootSupplementsIndexVue,
  },
})
export default class AppAdministratorResourcesRootIndexVue extends Vue {}
