
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store";

// Models
import {AppCommonExercise} from "@/models/app/common/exercise";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import {InfoFilled} from "@element-plus/icons-vue";
import AppAdministratorResourcesRootExercisesFormLookupVue from "@/views/app/administrator/resources/root/exercises/form/lookup.vue";

// Services
import {getRequest} from "@/services/api/request";

@Options({
  components: {
    ElRow,
    ElCol,
    InfoFilled,
    AppAdministratorResourcesRootExercisesFormLookupVue,
  },
})
export default class AppAdministratorResourcesRootExercisesListIndexVue extends Vue {
  isLoading: boolean | null = false;

  exercises: Array<AppCommonExercise> = [];

  async getExercises(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/administration/exercises`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.exercises = r.data;
      }
    });
    this.isLoading = false;
  }

  async created(): Promise<void> {
    await this.getExercises();
  }
}
