import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51f8bd74"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "navigation" }
const _hoisted_2 = { class: "navigation-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", null, [
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: { name: 'AppAdministratorResourcesRootIndexVue' } }, {
            default: _withCtx(() => [
              _createTextVNode(" Resursi ")
            ]),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: { name: 'AppAdministratorResourcesExercisesIndexVue' } }, {
            default: _withCtx(() => [
              _createTextVNode(" Vježbe ")
            ]),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: { name: 'AppAdministratorResourcesDietsIndexVue' } }, {
            default: _withCtx(() => [
              _createTextVNode(" Prehrana ")
            ]),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: { name: 'AppAdministratorResourcesSupplementsIndexVue' } }, {
            default: _withCtx(() => [
              _createTextVNode(" Suplementi ")
            ]),
            _: 1
          })
        ])
      ])
    ])
  ]))
}